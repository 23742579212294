import './styles.scss';

import React, { useEffect, useMemo, useState } from 'react';

import { Alert, Button, Form, message, Typography } from 'antd';
import { MdArrowRight } from 'react-icons/md';
import OtpInput from 'react-otp-input';
import { Link, useLocation } from 'react-router-dom';

import API from '../../api';
import UserAPI from '../../api/UserAPI';
import tmlogo from '../../assets/svg/tmlogo.svg';
import Loader from '../../components/Loader';
import ConfirmationModal from '../../components/Modals/ConfirmationModal/ConfirmationModal';
import { clearActivity } from '../../context/activityReducer';
import { setLoggedIn } from '../../context/appReducer';
import { useTheme } from '../../context/ThemeProvider';
import { setUserDetails } from '../../context/userReducer';
import { REFRESH_TOKEN_KEY } from '../../shared/data';
import showAppError from '../../shared/error';
import {
  useAppDispatch,
  useAppNavigate,
  useAppSelector,
} from '../../shared/hooks';
import {
  extract,
  navigateToOnboardingScreen,
  setLocalData,
} from '../../shared/utils';
import { ROUTES } from '../../types/routes';
import { IUserDetails, OnboardingStatus } from '../../types/userTypes';
import LoginHeader from './LoginHeader';

interface PhoneOrEmail {
  name?: string;
  phone?: string;
  email?: string;
  mode: 'phone' | 'email';
  countryCode: string;
  blockOtp?: boolean;
  supportNumber?: string;
  flow?: 'login' | 'number' | 'email' | 'signup';
  referNEarnId?: string;
}

interface LocationState extends PhoneOrEmail {
  redirectTo?: string;
  search?: string;
}

const VerifyOTPBox: React.FC = ({}) => {
  const navigate = useAppNavigate();
  const location = useLocation();

  const dispatch = useAppDispatch();
  const { colors } = useTheme();
  const {
    isTagMango,
    hostMetadata: { logo, loginMethods },
  } = useAppSelector((state) => state.app);

  const [otp, setOtp] = useState<string>('');
  const [loader, setLoader] = useState<boolean>(false);
  // const optInputRef = React.useRef<OtpInput>(null);
  const [userPhoneOrEmail, setUserPhoneOrEmail] = useState<PhoneOrEmail>();
  const [redirectState, setRedirectState] = useState<{
    redirectTo: string;
    search?: string;
    state: any;
  } | null>(null);

  const [disableResendButton, setDisableResendButton] = useState(true);
  const [resendButtonClickCount, setResendButtonClickCount] = useState(0);
  const [openValidationModal, setOpenValidationModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(30);

  const onSuccessfulLogin = async (creatorObject: IUserDetails) => {
    dispatch(
      setUserDetails({
        email: creatorObject.email || '',
        name: creatorObject.name || '',
        phone: creatorObject.phone || null,
        userSlug: creatorObject.userSlug || '',
        type: (creatorObject.onboarding as OnboardingStatus) || '',
        gstNumber: creatorObject.gstNumber || '',
        currency: creatorObject.currency || '',
        tmCommission: creatorObject?.tmCommission || 0,
        country: creatorObject?.country || '',
      }),
    );

    dispatch(setLoggedIn(true));
    dispatch(clearActivity());
    setTimeout(() => {
      if (creatorObject.onboarding !== 'started' && redirectState) {
        navigate(
          redirectState.redirectTo as ROUTES,
          {},
          {
            state: redirectState.state,
            search: redirectState.search,
            reset: true,
          },
        );
      } else {
        navigateToOnboardingScreen(
          creatorObject.onboarding as OnboardingStatus | null,
          navigate,
          true,
        );
      }
    }, 200);
  };

  useEffect(() => {
    if (disableResendButton) {
      setTimeout(() => {
        setDisableResendButton(false);
      }, 30000);
    }
  }, [disableResendButton]);

  useEffect(() => {
    if (timer) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }
  }, [timer]);

  const verifyOtp = async (otpValue: string, logoutAll: boolean = false) => {
    if (!userPhoneOrEmail) return;
    setLoader(true);
    if (userPhoneOrEmail.flow === 'number') {
      UserAPI.changeNumber(userPhoneOrEmail.phone || '', otpValue)
        .then(() => {
          dispatch(
            setUserDetails({
              phone: Number(userPhoneOrEmail.phone) || null,
              requiresPostMigrationVerification: false,
            }),
          );
          message.success('Phone number successfully updated');
          navigate(ROUTES.HOME);
        })
        .catch((error: any) => {
          if (error.status === 400 && error.data === 'OTP Expired') {
            message.error('OTP Expired');
            setOtp('');
            setTimer(0);
            setLoader(false);
            return;
          } else {
            showAppError(error);
            setOtp('');
          }
        })
        .finally(() => {
          setLoader(false);
        });
    } else if (userPhoneOrEmail.flow === 'email') {
      UserAPI.changeEmail(userPhoneOrEmail.email || '', otpValue)
        .then(() => {
          dispatch(
            setUserDetails({
              email: (userPhoneOrEmail.email || '').trim(),
              isEmailVerified: true,
            }),
          );
          message.success('Email successfully updated');
          navigate(ROUTES.HOME);
        })
        .catch((error: any) => {
          if (error.status === 400 && error.data === 'OTP Expired') {
            message.error('OTP Expired');
            setOtp('');
            setTimer(0);
            setLoader(false);
            return;
          } else {
            showAppError(error);
            setOtp('');
          }
        })
        .finally(() => {
          setLoader(false);
        });
    } else if (userPhoneOrEmail.flow === 'signup') {
      try {
        const response = await API.verifySignupOTP({
          otp: otpValue,
          phone: userPhoneOrEmail.phone || '',
          email: userPhoneOrEmail.email || '',
          name: userPhoneOrEmail.name || '',
          refernEarnId: userPhoneOrEmail.referNEarnId,
        });

        if (response.status == 200) {
          localStorage.setItem(
            REFRESH_TOKEN_KEY,
            response.data?.result?.refreshToken,
          );
          setLocalData(response.data?.result?.accessToken);
          dispatch(
            setUserDetails({
              name: response.data?.result?.name,
              email: response.data?.result?.email,
              phone: Number(response.data?.result?.phone) || null,
            }),
          );
          const userData = (await UserAPI.getLatestUserDetails()).data.result;
          onSuccessfulLogin(userData);
        } else {
          showAppError(response.data);
          setOtp('');
        }
      } catch (error: any) {
        if (error?.response?.status === 406) {
          setOpenValidationModal(true);
          setLoader(false);
          return;
        } else if (
          error?.response?.status === 400 &&
          error?.response?.data === 'OTP Expired'
        ) {
          message.error('OTP Expired');
          setOtp('');
          setTimer(0);
          setLoader(false);
          return;
        } else {
          showAppError(error);
          setOtp('');
        }
      } finally {
        setLoader(false);
      }
    } else {
      try {
        const response = await API.verifyOTP({
          deviceId: '',
          mode: userPhoneOrEmail.mode,
          otp: otpValue,
          phone: userPhoneOrEmail[userPhoneOrEmail.mode] || '',
          logoutAll: logoutAll,
          refernEarnId: userPhoneOrEmail.referNEarnId,
        });

        if (response.status == 200) {
          localStorage.setItem(
            REFRESH_TOKEN_KEY,
            response.data?.result?.refreshToken,
          );
          setLocalData(response.data?.result?.accessToken);
          dispatch(
            setUserDetails({
              name: response.data?.result?.name,
              email: response.data?.result?.email,
              phone: Number(response.data?.result?.phone) || null,
            }),
          );
          const userData = (await UserAPI.getLatestUserDetails()).data.result;
          onSuccessfulLogin(userData);
        } else {
          showAppError(response.data);
          setOtp('');
        }
      } catch (error: any) {
        if (error?.response?.status === 406) {
          setOpenValidationModal(true);
          setLoader(false);
          return;
        } else if (
          error?.response?.status === 400 &&
          error?.response?.data === 'OTP Expired'
        ) {
          message.error('OTP Expired');
          setOtp('');
          setTimer(0);
          setLoader(false);
          return;
        } else {
          showAppError(error);
          setOtp('');
        }
      } finally {
        setLoader(false);
      }
    }
  };

  const handleOk = () => {
    setOpenValidationModal(false);
    verifyOtp(otp, true);
  };

  const handleCancel = () => {
    setOpenValidationModal(false);
    setTimer(0);
    setOtp('');
  };

  const resend = async () => {
    if (!userPhoneOrEmail) return;
    try {
      setLoading(true);
      setDisableResendButton(true);
      setTimer(30);
      const resp =
        userPhoneOrEmail.flow === 'number'
          ? await UserAPI.getChangeNumberOTP(userPhoneOrEmail.phone || '')
          : userPhoneOrEmail.flow === 'email'
          ? await UserAPI.getChangeEmailOTP(userPhoneOrEmail.email || '')
          : userPhoneOrEmail.flow === 'signup'
          ? await UserAPI.registerUser(
              userPhoneOrEmail.name || '',
              userPhoneOrEmail.phone || '',
              userPhoneOrEmail.email || '',
              userPhoneOrEmail.countryCode,
              true,
            )
          : await API.getOTP(
              userPhoneOrEmail[userPhoneOrEmail.mode] || '',
              userPhoneOrEmail.mode,
              userPhoneOrEmail.blockOtp,
              true,
              userPhoneOrEmail.supportNumber,
            );
      if (resp.status === 200) {
        setResendButtonClickCount(resendButtonClickCount + 1);
        message.success('OTP Sent');
      } else {
        showAppError(resp.data);
      }
    } catch (error: any) {
      showAppError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const state = location.state as LocationState;
    if ((state?.phone || state?.email) && state?.mode) {
      setUserPhoneOrEmail({
        phone: state.phone,
        email: state.email,
        mode: state.mode as 'phone' | 'email',
        countryCode: state.countryCode,
        blockOtp: state.blockOtp,
        supportNumber: state.supportNumber,
        flow: state.flow || 'login',
        name: state.name,
        referNEarnId: state.referNEarnId,
      });
      if (state.redirectTo) {
        setRedirectState({
          redirectTo: state.redirectTo,
          search: state.search,
          state:
            extract(
              state as Record<string, any>,
              [
                'phoneOrEmail',
                'mode',
                'countryCode',
                'blockOtp',
                'supportNumber',
                'redirectTo',
                'referNEarnId',
              ],
              true,
            ) || {},
        });
      }
    } else {
      navigate(ROUTES.LOGIN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (otp.length === 6) {
      verifyOtp(otp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp]);

  const fields = useMemo(() => {
    if (!userPhoneOrEmail) return '';
    let elems: (string | null)[] = [];
    elems = (
      (loginMethods && loginMethods.length > 0
        ? loginMethods
        : userPhoneOrEmail.countryCode === 'IN'
        ? ['phone', 'email']
        : ['email']) as (keyof PhoneOrEmail)[]
    ).map((key) =>
      userPhoneOrEmail[key] ? userPhoneOrEmail[key]?.toString() || '' : null,
    );
    elems = elems.filter((elem) => elem !== null);
    return elems.join(' and ');
  }, [userPhoneOrEmail, loginMethods]);

  return loader || !userPhoneOrEmail ? (
    <Loader />
  ) : (
    <div className="loginBox">
      <LoginHeader
        title="Verify OTP"
        subtitle={`Please enter the OTP sent to ${fields}`}
        logo={isTagMango ? tmlogo : logo}
      />
      <Form layout="vertical" className="loginForm">
        <Form.Item>
          <OtpInput
            containerStyle="loginOTPContainer"
            inputStyle="loginOTPInput"
            // focusStyle="loginOTPInputFocus"
            shouldAutoFocus
            numInputs={6}
            value={otp}
            onChange={setOtp}
            renderInput={(props) => <input {...props} type="tel" />}
          />
        </Form.Item>
        {resendButtonClickCount === 3 ? (
          <>
            <Alert
              message="Too many attempts, try again later  "
              type="error"
              action={<Link to="/"> Home </Link>}
            />
          </>
        ) : (
          <>
            {disableResendButton ? (
              <Typography.Text className="siteText">
                Resend OTP in {timer}s
              </Typography.Text>
            ) : (
              <Typography.Text className="siteText">
                Didn&apos;t receive a code?
              </Typography.Text>
            )}
            <Button
              className="siteBtn siteBtnLink"
              style={
                ((disableResendButton || resendButtonClickCount === 3) && {
                  color: '#D3D3D3',
                }) ||
                {}
              }
              onClick={resend}
              loading={loading}
              disabled={disableResendButton || resendButtonClickCount === 3}>
              Resend{' '}
              <MdArrowRight
                color={
                  disableResendButton || resendButtonClickCount === 3
                    ? '#D3D3D3'
                    : colors.PRIMARY
                }
                size={25}
              />
            </Button>
            {disableResendButton || resendButtonClickCount === 3 ? null : (
              <Typography.Text
                className="siteText"
                style={{
                  fontSize: '13px',
                }}>
                Please click on &quot;Resend&quot; to receive OTP on your
                {userPhoneOrEmail.countryCode === 'IN'
                  ? ' WhatsApp and Email'
                  : ' Email'}
              </Typography.Text>
            )}
          </>
        )}
      </Form>
      <ConfirmationModal
        title="Login Here"
        open={openValidationModal}
        handleOk={handleOk}
        handleCancel={handleCancel}
        message="You are already signed in from another device. Continuing here will log you out from other devices."
        okayButtonText="Continue"
        cancelButtonText="Cancel"
        loading={loader}
      />
    </div>
  );
};

export default VerifyOTPBox;
