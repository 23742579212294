import './styles.scss';

import React, { useEffect, useMemo, useState } from 'react';

import { Button, Form, Input, message, Space, Typography } from 'antd';
import { MdWarning } from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';

import API from '../../api/index';
import Assets from '../../assets';
import tmlogo from '../../assets/svg/tmlogo.svg';
import CopyrightContent from '../../components/CopyrightContent';
import Loader from '../../components/Loader';
import { useTheme } from '../../context/ThemeProvider';
import showAppError from '../../shared/error';
import { useAppNavigate, useAppSelector } from '../../shared/hooks';
import { emailRegex, rgx10digit } from '../../shared/utils';
import { ROUTES } from '../../types/routes';
import LoginHeader from './LoginHeader';
import LoginOrSignupBottomAction from './LoginOrSignupBottomAction';

const LoginBox: React.FC = () => {
  const { colors: COLORS } = useTheme();

  const {
    hostMetadata: {
      appName,
      logo,
      customSupportLink,
      loginScreenTitle,
      loginMethods,
    },
    isTagMango,
  } = useAppSelector((state) => state.app);

  const [form] = Form.useForm();

  const [mode, setMode] = useState<'phone' | 'email'>('email');
  const [loading, setLoading] = useState(false);
  const [countryLoading, setCountryLoading] = useState(false);
  const [showUserExists, setShowUserExists] = useState(false);
  const navigate = useAppNavigate();
  const location = useLocation();

  const getCountry = async () => {
    setCountryLoading(true);
    const response = await API.getCurrentCountry();
    if (response.data.code === 0) {
      const data = response?.data?.result;
      const cc = JSON.parse(data as string)?.countryCode;
      if (cc === 'IN') {
        setMode('phone');
      }
      // setCountryCode(JSON.parse(data as string)?.countryCode);
    }
    setCountryLoading(false);
  };

  const validatePhoneNumber = (phoneNumber: string) => {
    if (!phoneNumber) {
      message.error('Please enter mobile number.');
      return false;
    }

    if (!rgx10digit.test(phoneNumber.replace(/\s/g, ''))) {
      message.error(
        'Please enter a valid 10-digit mobile number without country code.',
      );
      return false;
    }

    return true;
  };

  const validateEmail = (emailValue: string) => {
    if (!emailValue) {
      message.error('Please enter email.');
      return false;
    }
    if (emailRegex.test(emailValue)) {
      return true;
    }
    message.error('Please enter valid email.');
    return false;
  };

  const getOtp = async (values: any) => {
    setShowUserExists(false);
    let payload = values?.phoneOrMail;
    let supportNumber = null;
    let blockOtp = false;
    if (payload.replace(/\s/g, '').endsWith('#')) {
      blockOtp = true;
    }
    let [userNumber, sNumber] = payload.split('#');
    if (mode === 'phone') {
      if (!validatePhoneNumber(userNumber?.replace(/\s/g, ''))) return false;
      payload = userNumber.replace(/\s/g, '');
      if (sNumber) {
        supportNumber = sNumber.replace(/\s/g, '');
      }
    } else {
      if (!validateEmail(userNumber?.trim?.().toLowerCase?.())) return false;
      payload = userNumber.trim?.().toLowerCase?.();
      if (sNumber) {
        supportNumber = sNumber.replace(/\s/g, '');
      }
    }
    if (supportNumber && !validatePhoneNumber(supportNumber)) return false;
    try {
      setLoading(true);
      const response = await API.getOTP(
        payload,
        mode,
        blockOtp,
        false,
        supportNumber,
      );
      if (response.data.code === 0) {
        let redirectTo = '';
        const afterLoginRedirectTo = localStorage.getItem(
          'afterLoginRedirectTo',
        );
        if (afterLoginRedirectTo) {
          redirectTo = afterLoginRedirectTo;
          localStorage.removeItem('afterLoginRedirectTo');
        }
        navigate(
          ROUTES.VERIFY_OTP,
          {},
          {
            state: {
              [mode]: payload,
              mode,
              blockOtp,
              supportNumber,
              countryCode: mode === 'phone' ? 'IN' : undefined,
              ...(location.state || {}),
              ...(redirectTo ? { redirectTo } : {}),
            },
          },
        );
        message.success('OTP sent successfully.');
      } else {
        showAppError(response.data);
      }
    } catch (error: any) {
      if (
        error?.response?.data?.result &&
        typeof error?.response?.data?.result === 'string' &&
        error.response.data.result === 'Unregistered'
      ) {
        setShowUserExists(true);
      } else {
        showAppError(error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loginMethods && loginMethods.length > 0) {
      setMode(loginMethods[0]);
    } else {
      getCountry();
    }
  }, [loginMethods]);

  const numberExtension = useMemo(
    () =>
      mode === 'phone' ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}>
          {/* <ReactFlagsSelect
            selected="IN"
            disabled
            onSelect={() => {}}
            searchable={false}
            showSelectedLabel={false}
            id="flags-select"
            className="countryFlag"
          /> */}
          {
            // indian flag
          }
          <svg
            width="30"
            height="24"
            viewBox="0 0 512 336"
            xmlns="http://www.w3.org/2000/svg">
            <g fill="none">
              <path
                d="M512.001 112.092H0V9.103A8.829 8.829 0 018.828.275h494.345a8.829 8.829 0 018.828 8.828v102.989z"
                fill="#FAB446"></path>
              <path
                d="M503.172 335.724H8.828A8.829 8.829 0 010 326.896V223.908h512v102.988a8.828 8.828 0 01-8.828 8.828z"
                fill="#73AF00"></path>
              <path fill="#F5F5F5" d="M0 112.088h512V223.9H0z"></path>
              <g transform="translate(208 120)" fill="#41479B">
                <path d="M48 95.448C21.836 95.448.552 74.164.552 48S21.836.552 48 .552 95.448 21.836 95.448 48 74.164 95.448 48 95.448zm0-86.069C26.702 9.379 9.379 26.702 9.379 48c0 21.298 17.323 38.621 38.621 38.621 21.298 0 38.621-17.323 38.621-38.621C86.621 26.702 69.298 9.379 48 9.379z"></path>
                <circle cx="48" cy="48" r="5.379"></circle>
                <path d="M48 48.807l-13.67 1.38-29.364-1.38v-1.614l29.364-1.38L48 47.193zm0 0l13.67 1.38 29.364-1.38v-1.614l-29.364-1.38L48 47.193z"></path>
                <path d="M47.193 48l-1.38-13.67 1.38-29.364h1.614l1.38 29.364L48.807 48zm0 0l-1.38 13.67 1.38 29.364h1.614l1.38-29.364L48.807 48z"></path>
                <path d="M47.43 48.57l-10.642-8.689L17 18.141 18.141 17l21.74 19.788L48.57 47.43z"></path>
                <path d="M47.43 48.57l8.689 10.642L77.859 79 79 77.859l-19.788-21.74L48.57 47.43z"></path>
                <path d="M47.43 47.43l8.689-10.642L77.859 17 79 18.141l-19.788 21.74L48.57 48.57z"></path>
                <path d="M47.43 47.43l-10.642 8.689L17 77.859 18.141 79l21.74-19.788L48.57 48.57z"></path>
                <path d="M48.309 48.746l-12.102 6.506-27.656 9.962-.618-1.491 26.601-12.512 13.157-3.957zm0 0l13.157-3.957 26.601-12.512-.618-1.491-27.656 9.962-12.102 6.506z"></path>
                <path d="M47.254 48.309l-6.506-12.102-9.962-27.656 1.491-.618 12.512 26.601 3.957 13.157zm0 0l3.957 13.157 12.512 26.601 1.491-.618-9.962-27.656-6.506-12.102z"></path>
                <path d="M47.691 48.746l-13.157-3.957L7.933 32.277l.618-1.491 27.656 9.962 12.102 6.506zm0 0l12.102 6.506 27.656 9.962.618-1.491-26.601-12.512-13.157-3.957z"></path>
                <path d="M47.254 47.691l3.957-13.157L63.723 7.933l1.491.618-9.962 27.656-6.506 12.102zm0 0l-6.506 12.102-9.962 27.656 1.491.618 12.512-26.601 3.957-13.157z"></path>
              </g>
              <circle fill="#F5F5F5" cx="256" cy="168" r="7.256"></circle>
              <circle fill="#41479B" cx="256" cy="168" r="4.351"></circle>
            </g>
          </svg>
          <Typography.Text>+91</Typography.Text>
        </div>
      ) : null,
    [mode],
  );

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  return countryLoading ? (
    <Loader />
  ) : (
    <div className="loginBox">
      <LoginHeader
        title={
          !isTagMango && loginScreenTitle
            ? loginScreenTitle
            : `Log in to ${appName || 'TagMango'}`
        }
        subtitle="Welcome back! Please enter your details."
        logo={isTagMango ? tmlogo : logo}
      />

      <Form
        form={form}
        layout="vertical"
        className="loginForm"
        onFinish={getOtp}>
        <Form.Item name="phoneOrMail">
          {mode === 'phone' ? (
            <Input
              addonBefore={numberExtension}
              placeholder="Enter your phone"
              className="loginInputAddon"
              type="tel"
            />
          ) : (
            <Input
              addonBefore={numberExtension}
              placeholder="Enter your email"
              className="loginInput"
              type="email"
            />
          )}
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          block
          loading={loading}
          className="loginFormSubmitBtn">
          Request OTP
        </Button>

        {!loginMethods || loginMethods.length < 1 || loginMethods.length > 1 ? (
          <p className="loginFormFooter">
            OR,{' '}
            <a onClick={() => setMode(mode === 'phone' ? 'email' : 'phone')}>
              Login using {mode === 'phone' ? 'email' : 'phone'}
            </a>
          </p>
        ) : null}

        <LoginOrSignupBottomAction action="signup" />
      </Form>

      {showUserExists ? (
        <div className="loginFormWarning">
          <MdWarning
            size={18}
            color={COLORS.WARNING}
            style={{ marginTop: 0, marginRight: 8 }}
          />

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}>
            <Typography.Title level={5}>Attention Needed</Typography.Title>

            <Typography.Paragraph>
              The number/email you're trying to login with doesn't exist.
              <br />
              If you want to{' '}
              {isTagMango ? 'become a creator' : 'register as a new user'} click
              the button below
            </Typography.Paragraph>

            <Link
              to={ROUTES.SIGNUPCREATOR}
              onClick={(e) => {
                e.preventDefault();
                navigate(
                  ROUTES.SIGNUPCREATOR,
                  {},
                  {
                    state: {
                      [mode]: form.getFieldValue('phoneOrMail'),
                      mode,
                      ...(location.state || {}),
                    },
                  },
                );
              }}>
              {isTagMango ? 'Sign up as Creator' : 'Sign up as New User'}
            </Link>
          </div>
        </div>
      ) : null}

      <div className="loginBoxFooter">
        <div className="loginCopyright">
          <CopyrightContent showWhiteLabelAppName />
        </div>
        <Space size="large">
          <a
            className="loginv1BoxFooterLink"
            href={Assets.links.termsAndConditionsPage}
            target="_blank">
            Terms of Use
          </a>
          <a
            className="loginv1BoxFooterLink"
            href={Assets.links.privacyPolicyPage}
            target="_blank">
            Privacy Policy
          </a>
          <a
            href={customSupportLink || 'https://help.tagmango.com/hc/en-us'}
            target="_blank"
            className="loginv1BoxFooterLink"
            rel="noreferrer">
            Help Centre
          </a>
        </Space>
      </div>
    </div>
  );
};

export default LoginBox;
