import TMIcon from 'tm-icons-library';

import Header from '../../../../components/Header/Header';
import SettingsItem from '../../../../components/SettingsItem/SettingsItem';
import { useTheme } from '../../../../context/ThemeProvider';
import { useAppNavigate } from '../../../../shared/hooks';
import { ROUTES } from '../../../../types/routes';

const title = 'Account Settings';

const AccountSettings: React.FC = () => {
  const navigate = useAppNavigate();
  const { colors } = useTheme();

  return (
    <div className="pageRoot">
      <Header title={title} />
      <div className="pageContent">
        <SettingsItem
          onClick={() => {
            navigate(ROUTES.CHANGE_NUMBER);
          }}
          icon={<TMIcon name="call" size={20} color={colors.ICON} />}
          title="Change registered number"
        />
        <SettingsItem
          onClick={() => {
            navigate(ROUTES.CHANGE_EMAIL);
          }}
          icon={<TMIcon name="mail" size={20} color={colors.ICON} />}
          title="Change registered email"
        />
        <SettingsItem
          onClick={() => {
            navigate(ROUTES.DELETE_ACCOUNT);
          }}
          icon={<TMIcon name="trash" size={20} color={colors.DANGER} />}
          warning
          title="Delete my account"
          description="Delete all account information"
        />
      </div>
    </div>
  );
};

export default AccountSettings;
